<script setup lang="ts">
const permissionStore = usePermissionsStore();
const web3Store = useWeb3Store();

function onClose(val: boolean) {
  if (!val) {
    web3Store.connectModal = true;
  }
}
</script>

<template>
  <RiseModal
    v-model="web3Store.reattempt"
    no-footer
    @update:modelValue="onClose"
  >
    <div class="flex flex-col gap-2 max-w-[400px]">
      <div>
        The wallet that you just connected is not authorized to execute actions
        on your account. Please make sure to connect a wallet with permissions
        to execute actions.
      </div>
      <div class="font-bold text-lg">Authorized Wallets:</div>
      <div v-for="wallet in permissionStore.permissions" :key="wallet.address">
        <div
          class="flex items-center gap-2 bg-gray-100 dark:bg-gray-900 p-2 rounded-md"
        >
          <div class="text-sm font-mono">{{ wallet.address }}</div>
          <div
            class="px-2 py-1 text-xs font-semibold bg-gray-200 dark:bg-gray-800 rounded-md"
          >
            {{ wallet.role }}
          </div>
        </div>
      </div>
    </div>
  </RiseModal>
</template>
