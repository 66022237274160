<script setup lang="ts">
const route = useRoute();

useHead({
  titleTemplate: () => {
    return route.meta.title ? `${route.meta.title} - Rise Pay` : "Rise Pay";
  },
});
const sessionStore = useSessionStore();
const clerkUserStore = useClerkUserStore();
const onboardingStore = useOnboardingStore();
const userStore = useUserStore();
const runtimeConfig = useRuntimeConfig();
sessionStore.refreshSession();
const notificationsStore = useNotificationsStore();
const permissionStore = usePermissionsStore();

const isMobileOpen = ref(false);

function handleSidebarToggle(isOpen: boolean) {
  isMobileOpen.value = isOpen;
}

onMounted(async () => {
  clerkUserStore.setUser();

  if (!onboardingStore.onboarding.onboarded) {
    await onboardingStore.getOnboarding();
    if (runtimeConfig.public.isLocalDevelopment) return;
    window.location.replace(`${runtimeConfig.public.appUrl}/onboarding/`);

    return;
  }

  try {
    notificationsStore.loading = true;
    userStore.loading = true;
    await notificationsStore.getNotifications({ action: "invite" });
    await userStore.getMe();
    await permissionStore.getRiseIDPermissions(
      userStore.me.user?.nanoid as string
    );
  } finally {
    notificationsStore.loading = false;
    userStore.loading = false;
  }
});
</script>

<template>
  <div class="text-gray-900 dark:text-white">
    <WalletNotAuthorized />
    <Web3Modal />
    <div v-if="route.meta.blank">
      <slot />
    </div>
    <div v-else class="flex min-h-[100dvh] print:!p-0">
      <LeftMenu @on-toggle="handleSidebarToggle" />
      <div
        class="pt-24 md:pt-0 md:pl-[220px] flex-grow max-w-[100dvw]"
        :class="{ 'overflow-hidden': isMobileOpen }"
      >
        <slot />
      </div>
    </div>
  </div>
</template>
