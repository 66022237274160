<script setup lang="ts">
import { useWeb3Store } from "~/stores/utils/web3";

const web3Store = useWeb3Store();
const clerkUserStore = useClerkUserStore();

function onClose() {
  web3Store.connectModal = false;
  window.postMessage({ type: "web3modal-interrupted" });
}
</script>

<template>
  <Connector
    v-if="web3Store.connectModal && clerkUserStore.email"
    :email="clerkUserStore.email"
    @close="onClose"
  />
  <SignData v-if="web3Store.signData" />
</template>
