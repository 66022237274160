<script setup lang="ts">
type NavigationItemChildren = {
  title: string;
  to: string;
};

const { icon, to, title, number, target } = defineProps<{
  icon: string;
  to?: string | object;
  title: string;
  number?: string | number;
  label?: string;
  target?: string;
  children?: NavigationItemChildren[];
}>();

const open = ref(false);

const relationshipsStore = useRelationshipsStore();
const route = useRoute();
</script>

<template>
  <nuxt-link
    v-if="to"
    active-class="is-active text-primary-200 bg-gradient-22"
    :to="
      relationshipsStore.currentScope === 'unknown'
        ? `/account/${to}`
        : `/${relationshipsStore.currentScope}/${route.params.workspaceId}/${to}`
    "
    :target="target"
    class="group transition flex flex-col h-[45px] w-full px-3 cursor-pointer text-primary-50 hover:bg-gray-900/50 dark:text-white"
  >
    <div
      class="hidden group-[.is-active]:block h-[1px] bg-gradient-23 w-full"
    />
    <div class="flex flex-grow items-center">
      <div class="mr-4 flex h-5 w-5 items-center justify-center">
        <i class="ri-fw text-LG" :class="icon"></i>
      </div>
      <span class="text-sm font-medium">
        {{ title }}
      </span>
      <div
        v-if="Number(number) > 0"
        class="ml-2 flex h-5 w-5 items-center justify-center rounded-full text-xs font-medium text-[#2A095B] bg-[#F0E8FD]"
      >
        {{ number }}
      </div>
      <span
        v-if="label"
        class="bg-gradient-10 ml-3 rounded px-2 py-[2px] text-[10px] font-bold text-white"
      >
        {{ label }}
      </span>
    </div>
    <div
      class="hidden group-[.is-active]:block h-[1px] bg-gradient-23 w-full"
    />
  </nuxt-link>
  <div v-else>
    <div
      active-class="is-active text-primary-200 bg-gradient-22"
      class="group transition flex flex-col h-[45px] w-full px-3 cursor-pointer text-primary-50 hover:bg-gray-900/50 dark:text-white"
      @click="open = !open"
    >
      <div
        class="hidden group-[.is-active]:block h-[1px] bg-gradient-23 w-full"
      />
      <div class="flex flex-grow items-center">
        <div class="mr-4 flex h-5 w-5 items-center justify-center">
          <i class="ri-fw text-lg" :class="icon"></i>
        </div>
        <span class="text-sm font-medium">
          {{ title }}
        </span>
        <div
          v-if="Number(number) > 0"
          class="ml-2 flex h-5 w-5 items-center justify-center rounded-full text-xs font-medium text-[#2A095B] bg-[#F0E8FD]"
        >
          {{ number }}
        </div>
        <span
          v-if="label"
          class="bg-gradient-10 ml-3 rounded px-2 py-[2px] text-[10px] font-bold text-white"
        >
          {{ label }}
        </span>
        <div
          v-if="children"
          class="ml-auto flex h-5 w-5 items-center justify-center group-hover:translate-x-1 transition"
          :class="open ? 'transform rotate-90' : ''"
        >
          <i class="ri-fw ri-arrow-right-s-line text-lg" />
        </div>
      </div>
      <div
        class="hidden group-[.is-active]:block h-[1px] bg-gradient-23 w-full"
      />
    </div>
    <Transition enter-active-class="animate-slideFadeIn">
      <div v-if="open">
        <nuxt-link
          v-for="child in children"
          :key="child.to"
          active-class="is-active text-primary-200 bg-gradient-22"
          :to="
            relationshipsStore.currentScope === 'unknown'
              ? `/account/${child.to}`
              : `/${relationshipsStore.currentScope}/${route.params.workspaceId}/${child.to}`
          "
          :target="target"
          class="group transition flex flex-col h-[45px] w-full cursor-pointer text-primary-50 hover:bg-gray-900/50 dark:text-white"
        >
          <div
            class="hidden group-[.is-active]:block h-[1px] bg-gradient-23 w-full"
          />
          <div class="flex flex-grow items-center">
            <div class="mr-4 flex h-5 w-5 items-center justify-center"></div>
            <span class="text-sm font-medium">
              {{ child.title }}
            </span>
          </div>
          <div
            class="hidden group-[.is-active]:block h-[1px] bg-gradient-23 w-full"
          />
        </nuxt-link>
      </div>
    </Transition>
  </div>
</template>
